/** @jsxImportSource @emotion/react */
import styled from '@emotion/styled';
import { FieldProps, FormikProps } from 'formik';
import { COLORS as SHARED_COLORS, fontAvenir, SHADES } from 'packages/constants';
import React, { forwardRef } from 'react';

import { COLORS, TIMING } from '@/constants/styles';

import { Box } from '../Div';
import { Label } from './Label';
import { SubText } from './SubText';

const BaseInput = forwardRef<
  HTMLInputElement,
  Omit<React.InputHTMLAttributes<HTMLInputElement>, 'form'> & {
    className?: string;
    form?: FormikProps<any>;
    trim?: boolean;
    fieldName: string;
  }
>(({ className, form, trim = false, fieldName, ...props }, ref) => {
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    // Need Formik form prop to be passed for this to work.
    if (!form) {
      return;
    }

    let value = e.target.value || '';

    if (trim) {
      value = value.toLowerCase().trim();
    }

    form.setFieldValue(fieldName, value);
  };

  return <input className={className} id={fieldName} {...props} onChange={props.onChange || handleChange} ref={ref} />;
});

BaseInput.displayName = 'BaseInput';

const filteredProps = ['hasErrors', 'meta', 'touched'];

export const StyledInput = styled(BaseInput, { shouldForwardProp: prop => !filteredProps.includes(prop.toString()) })<{
  hasErrors?: boolean;
}>`
  ${fontAvenir}
  border-radius: 5px;
  border: solid 2px ${({ hasErrors }) => (hasErrors ? COLORS.lightRed : SHARED_COLORS.Neutral[100])};
  box-shadow: inset 0 1px 2px 0 ${SHADES.Neutral[50]};
  font-size: 14px;
  line-height: 20px;
  color: ${COLORS.slate};
  box-sizing: border-box;
  width: 100%;
  padding: 6px 8px;
  outline: none;
  cursor: text;
  transition: border-color ${TIMING.fast()} ease-in-out;

  &:hover {
    border-color: ${({ hasErrors }) => (hasErrors ? COLORS.lightRed : SHARED_COLORS.Neutral[200])};
  }

  &:focus {
    border-color: ${({ hasErrors }) => (hasErrors ? COLORS.lightRed : SHARED_COLORS.Purple.Primary)};
  }

  &::placeholder {
    color: ${COLORS.blueGrey};
    opacity: 1;
  }

  &[disabled],
  &[readonly] {
    background-color: ${COLORS.paleGrey};
    color: ${COLORS.blueGrey};
    user-select: none;
    pointer-events: none;
  }

  &[type='number']::-webkit-inner-spin-button,
  &[type='number']::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
`;

export interface InputProps extends Omit<React.InputHTMLAttributes<HTMLInputElement>, 'form'> {
  /** @errors if set to true will show a red outline around the input element if present  */
  errors?: boolean;
  field?: FieldProps['field'];
  /** Optional @label property will show a label element with text */
  label?: string;
  name?: string;
  touched?: boolean;
  styles?: any;
  type?: string;
  /** Optional @subtext to be displayed under the input element  */
  subtext?: JSX.Element | string;
  form?: FormikProps<any>;
  /** Optional @trim property which will remove all whitespace `onChange`  */
  trim?: boolean;
}

export const Input = forwardRef<HTMLInputElement, Omit<InputProps, 'css'>>(
  ({ className, errors, field, label, name, styles, subtext, type = 'text', ...props }, ref) => {
    const fieldName = (field && field.name) || name || '';

    return (
      <Box className={className} css={styles}>
        {!!label && <Label htmlFor={fieldName}>{label}</Label>}
        <StyledInput
          id={fieldName}
          fieldName={fieldName}
          type={type}
          {...field}
          hasErrors={errors}
          {...props}
          name={name}
          ref={ref}
        />
        {!!subtext && <SubText mt="6px">{subtext}</SubText>}
      </Box>
    );
  },
);

Input.displayName = 'Input';
