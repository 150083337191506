/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { Field, FieldProps } from 'formik';
import { COLORS as SHARED_COLORS } from 'packages/constants';
import React from 'react';

import { COLORS, TIMING } from '@/constants/styles';

import checkboxIcon from '../../assets/checkbox.svg';
import { Label } from './Label';

const CheckboxLabel = styled(Label)`
  position: relative;
  cursor: pointer;
`;

const baseStyle = (hasErrors: string | boolean = false) => css`
  appearance: none;
  width: 20px;
  height: 20px;
  border-radius: 3px;
  background-color: ${COLORS.white};
  border: solid 1px ${!!hasErrors ? COLORS.lightRed : COLORS.lightPeriwinkle};
  display: inline-block;
  position: relative;
  outline: none;
  cursor: pointer;
  transition: background-color ${TIMING.fastest()} ease-out;
  margin-right: 10px;

  &:not(:disabled) {
    &:focus,
    &:checked,
    &.checked,
    &:hover {
      border-color: ${SHARED_COLORS.Purple.Primary};
    }
  }

  &:checked,
  &.checked {
    background-image: url(${checkboxIcon});
    background-repeat: no-repeat;
    background-position: center;
    background-color: ${SHARED_COLORS.Purple.Primary};
  }

  &:disabled {
    cursor: not-allowed;

    &:checked,
    &.checked {
      background-color: ${COLORS.paleGrey};
    }
  }
`;

export { baseStyle as checkboxStyle };

interface Props extends Omit<React.InputHTMLAttributes<HTMLInputElement>, 'form'> {
  checked?: boolean;
  children?: React.ReactNode;
  disabled?: boolean;
  errors?: boolean | string;
  handleChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  name?: string;
  touched?: boolean;
  useField?: boolean;
  value?: string | number | string[];
}

interface InputProps extends Props {
  field?: FieldProps['field'];
  form?: FieldProps['form'];
}

const BaseCheckboxInput: React.FC<InputProps> = ({ field, form, handleChange, className, ...props }) => {
  const onChange = handleChange
    ? handleChange
    : (e: React.ChangeEvent<HTMLInputElement>) => {
        if (form && field) {
          form.setFieldValue(field.name, e.target.checked);
        }
      };
  const checked = form && field ? !!field.value : props.checked;
  return <input type="checkbox" onChange={onChange} className={className} {...props} checked={checked} />;
};

export const CheckboxInput = styled(BaseCheckboxInput)(({ errors }) => baseStyle(errors));

/**
 * @deprecated
 */
export const Checkbox: React.FC<Omit<Props, 'css'>> = ({ children, errors, name, useField = false, ...props }) => {
  return (
    <CheckboxLabel>
      {useField ? (
        <Field name={name} component={CheckboxInput} errors={errors} {...props} />
      ) : (
        <CheckboxInput name={name} errors={errors} {...props} />
      )}
      {children}
    </CheckboxLabel>
  );
};
