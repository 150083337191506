import styled from '@emotion/styled';
import { COLORS } from 'packages/constants';

import { Paragraph } from '../Paragraph';

export const Label = styled(Paragraph.withComponent('label'))`
  color: ${COLORS.Neutral[600]};
  display: flex;
  align-items: center;
  font-size: 12px;
  margin-bottom: 4px;
`;
