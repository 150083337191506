import styled from '@emotion/styled';
import { FieldProps } from 'formik';
import { COLORS as SHARED_COLORS, fontAvenir } from 'packages/constants';
import React from 'react';

import { COLORS } from '@/constants/styles';

import { Box } from '../Div';
import { Label } from './Label';
import { SubText } from './SubText';

const TextAreaContainer = styled(Box)<{ hasErrors: boolean }>`
  width: 100%;

  textarea {
    ${fontAvenir};
    resize: none;
    font-size: 14px;
    border-radius: 5px;
    border: 1px solid ${props => (props.hasErrors ? COLORS.lightRed : COLORS.lightPeriwinkle)};
    padding: 15px;
    width: 100%;
    box-sizing: border-box;

    &:focus {
      border: 1px solid ${SHARED_COLORS.Purple.Primary};
      outline: none;
    }

    &::placeholder {
      color: ${COLORS.blueGrey};
      opacity: 1;
    }

    &[disabled],
    &[readonly] {
      background-color: ${COLORS.paleGrey};
      color: ${COLORS.blueGrey};
      user-select: none;
      pointer-events: none;
    }
  }

  label {
    margin-bottom: 4px;
  }
`;

interface Props extends Omit<React.TextareaHTMLAttributes<HTMLTextAreaElement>, 'form'> {
  /** @errors if set to true will show a red outline around the input element if present  */
  errors?: boolean;
  field?: FieldProps['field'];
  /** Optional @label property will show a label element with text */
  label?: string;
  name?: string;
  touched?: boolean;
  /** Optional @subtext to be displayed under the input element  */
  subtext?: JSX.Element | string;
}

export const TextArea: React.FC<Omit<Props, 'css'>> = ({ errors, field, label, name, subtext, ...props }) => {
  const fieldName = field && field.name ? field.name : name ? name : '';

  return (
    <TextAreaContainer hasErrors={!!errors}>
      <Label htmlFor={fieldName}>{label}</Label>
      <textarea id={fieldName} {...field} {...props} />
      {!!subtext && <SubText mt="6px">{subtext}</SubText>}
    </TextAreaContainer>
  );
};
